import React from "react";

import { 
  withAuthenticationRequired 
, useAuth0
} from "@auth0/auth0-react";
import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner/index.js';
import { LicenseKeys } from '../components/LicenseKeys.tsx';
import TL from "../translations";

export const GET_LICENSEKEYS = gql`
query licenseKey_query {
  license_key {
    licenseKey
    id
    description
    completed
    status {
      id
      code:id
      name
      color
    }
    createdBy { id code:id email name }
    candidate { id code:id email name }    
    questionnaire {
      code:id
      name
    }
  }
}
`
export const LicenseKeysPage = () => {

  const { user } = useAuth0();
  const userIs = {
    supervisor : user ? user.rolesId.includes("Supervisor") : false
  , coach      : user ? user.rolesId.includes("Coach") : false
  }

  const {loading, error, data} = useQuery(GET_LICENSEKEYS);

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
      <div>
      <p>Listado de las Evaluaciones</p>
      <QueryResult error={error} loading={loading} data={data} >
      { userIs.coach &&
      <div style={{width:900}}>
        <p>Como coach, tienes el acceso para crear {TL.LicenseKeys} y asignarselas a tu cuachee.</p>
        <p>Deberas agregar la {TL.LicenseKey} especificando el nombre y correo electrónico del coachee.</p>
      </div>
      }
        <LicenseKeys license_keys={data?.license_key}/>
      </QueryResult>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(LicenseKeysPage, {
  onRedirecting: () => <SpinnerContainer />,
});