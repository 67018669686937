import React from 'react';
import {useState, useEffect } from 'react';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { FloatLabel } from 'primereact/floatlabel';
import { InputText } from 'primereact/inputtext';
        
import { useMutation, gql } from "@apollo/client"

import { DropdownGQL } from './DropdownGQL.tsx';
import { StatusColumn } from "../components/statusColumn.tsx"

export const AssesmentDialog = ({visible, onHide, assesment,licenseKey, setAssesment}) => {
    const combinedAssesment = {
        ...assesment
      , userName: licenseKey?.user?.name || ""
      , candidate:licenseKey?.candidate
      , licenseKey:{ 
            code: licenseKey?.id
          , licenseKey:licenseKey?.licenseKey
          , name:licenseKey?.name
          , description:licenseKey?.description
          , candidate:licenseKey?.candidate
        }
    }

    const [assesmentC, setAssesmentC] = useState(combinedAssesment);

    useEffect(() => {
        const combinedAssesment = {
            ...assesment
          , userName: licenseKey?.user?.name || ""
          , candidate:licenseKey?.candidate
          , licenseKey:{ 
                code: licenseKey?.id
              , licenseKey:licenseKey?.licenseKey
              , name:licenseKey?.name
              , description:licenseKey?.description
              , candidate:licenseKey?.candidate
            }
        }        
        setAssesmentC(combinedAssesment);
      }, [assesment,licenseKey]); 

    const GET_LIST_OF_VALUES = gql`
    query relationships {
        relationships {
            id
            code:id
            name
            color
        }
        users {
            id
            code:id
            name
            email
        }
        license_key {
            code: id
            licenseKey
            description
            name: licenseKey
            candidate { id code:id email name }              
        }
    }
    `;

const SET_ASSESMENT = gql`
    mutation assesment_mutation($id: Int!, $licenseKeyId: Int, 
        $userEmail: String, $userName: String
      , $candidateEmail: String, 
        $relationshipId:Int, $statusId: Int,
        $name: String, $description: String
    ) { assesment(
            id: $id
          , licenseKeyId: $licenseKeyId
          , userEmail:$userEmail
          , userName: $userName
          , candidateEmail: $candidateEmail
          , relationshipId: $relationshipId
          , statusId: $statusId
          , name: $name
          , description: $description
    ) {
        code
        success
        message
        assesment {
            id
            name
            description
            completed
            user { id code:id email name }
            candidate { id code:id email name }            
            relationship { id code:id name color minimum maximum}
            status { code:id name  color }
            licenseKey {
                licenseKey
                id
                description
                createdBy { id code:id email name }
                candidate { id code:id email name }                
            }
        }
      }
    }  
`;

    const [SetAssesment/*,SetAssesmentReturn*/] = useMutation(SET_ASSESMENT, {
        variables: {},
        onCompleted: ({assesment}) => {            
            onHide();
            setAssesment(assesment,"modify");
        },
      });


    const saveAssesment = () => {
        let userEmail : String ;
        let userName : String;
        let candidateEmail : String;

        userName = assesmentC.userName; 

        if(typeof assesmentC.user === "string") 
            userEmail=assesmentC.user;
        else
            userEmail=assesmentC.user?.email;

        if(typeof assesmentC.candidate === "string") 
            candidateEmail=assesmentC.candidate;
        else
            candidateEmail=assesmentC.candidate?.email;

        const variables = { 
            id: assesmentC?.id || 0
          , licenseKeyId: assesmentC?.licenseKey?.code          
          , userEmail: userEmail
          , userName: userName
          , candidateEmail: candidateEmail
          , relationshipId: assesmentC?.relationship?.code
          , statusId: 1
          , name: assesmentC?.name
          , description: assesmentC?.description
        }

        SetAssesment({
            variables: variables
        });        
    };

    const assesmentDialogFooter = 
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Save" icon="pi pi-check" onClick={saveAssesment} />     
        </React.Fragment>
    ;

    const licenseKeyOptionTemplate = (option) => 
        <div>
            <b>{option.licenseKey}</b><br/>
            { option.candidate 
            ? <span>{option?.candidate?.name} ({option?.candidate?.email})</span>
            : <span>Licensia no asignada</span>
            }
            <br/>
        </div>

    const selectedLicenseKeyTemplate = (option, props) => {
        if (option) {
            return (
                <div>
                <b>{option.licenseKey}</b><br/>
                { option.candidate 
                ? <span>{option?.candidate?.name} ({option?.candidate?.email})</span>
                : <span>Licensia no asignada</span>
                }
                <br/>                            
            </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _assesment = { ...assesmentC };
        _assesment[`${name}`] = val;        
        setAssesmentC(_assesment);
    };


    return(
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }} 
            breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Detalles de la Evaluación" modal className="p-fluid" 
            footer={assesmentDialogFooter}
        > 
            { licenseKey 
            ? <FloatLabel className="mt-6">
                <InputText id="candidate" value={licenseKey?.candidate?.name || "Sin Candidato"} className="p-inputtext-sm" disabled />
                <label htmlFor="candidate">Evaluado</label>
                <small id="username-help"><b>Licencia: </b>{licenseKey?.licenseKey}</small>                
              </FloatLabel>
            : <DropdownGQL id="candidate" placeholder="Elija la persona que sera evaluada" label="Candidato" 
                value={assesmentC.candidate}                 
                editable
                onChange={(e) => onInputChange(e, 'candidate')}
                gqlValues={GET_LIST_OF_VALUES} gqlData="users"                
              />
            }
            { !licenseKey && 
                <DropdownGQL id="licenseKey" placeholder="Elija la licencia" label="License Key"
                    value={assesmentC?.licenseKey}
                    itemTemplate={licenseKeyOptionTemplate}
                    valueTemplate={selectedLicenseKeyTemplate}
                    onChange={(e) => onInputChange(e, 'licenseKey')}
                    gqlValues={GET_LIST_OF_VALUES} gqlData="license_key"                
                />
            }

            { licenseKey && 0
            ? <FloatLabel className="mt-6">
                <InputText id="user" placeholder="Elija la persona que sera evaluador"
                 value={assesmentC?.user?.email } className="p-inputtext-sm"  
                 onChange={(e) => onInputChange(e, 'user')}
                />
                <label htmlFor="user">Evaluador</label>                
              </FloatLabel>
            : <DropdownGQL id="user" placeholder="Correo electrónico de la persona que sera evaluador" label="Correo electrónico del evaluador" 
                value={assesmentC?.user}
                onChange={(e) => onInputChange(e, 'user')}
                editable
                gqlValues={GET_LIST_OF_VALUES} gqlData="users"
              />
            }

              <FloatLabel className="mt-6">
                <InputText id="userName" placeholder="Nombre de la persona que sera evaluador"
                 value={assesmentC?.userName } className="p-inputtext-sm"  
                 onChange={(e) => onInputChange(e, 'userName')}
                />
                <label htmlFor="userName">Nombre completo del Evaluador</label>                
              </FloatLabel>


            <DropdownGQL id="relationship" placeholder="Elija una relación con el evaluador" label="Relación" 
                value={assesmentC?.relationship}                
                onChange={(e) => onInputChange(e, 'relationship')}
                gqlValues={GET_LIST_OF_VALUES} gqlData="relationships"
            />

        </Dialog>
    )   /*
            <FloatLabel className="mt-6">
                <InputText id="name"
                    value={assesmentC?.name}
                    onChange={(e) => onInputChange(e, 'name')}
                />
                <label htmlFor="name" >Nombre</label>
            </FloatLabel>
            
            <FloatLabel className="mt-6">
                <InputTextarea id="description"
                    rows={3}
                    value={assesmentC?.description}
                    onChange={(e) => onInputChange(e, 'description')}
                />
                <label htmlFor="description">Descripción</label>
            </FloatLabel>
    */
}

export const AssesmentDialogDelete = ({visible, onHide, assesment, setAssesment}) => {

    const DELETE_ASSESMENT = gql`
    mutation assesment_delete($id: Int!) {
        assesment_delete(id:$id) {
          id
        }
      }
    `

    const [DeleteAssesment/*, DeleteAssesmentReturn*/] = useMutation(DELETE_ASSESMENT, {
        variables: {},
        onCompleted: ({assesment_delete}) => {          
          onHide();
          setAssesment(assesment,"delete");
        },
      });

    const deleteAssesment = () => {
        const variables = { 
            id: assesment?.id || 0
        }
        DeleteAssesment({
            variables: variables
        });        
    };

    const assesmentDialogFooter = 
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Si" icon="pi pi-check" onClick={deleteAssesment} />     
        </React.Fragment>
    ;

    return(
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }} 
            breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Confirmar eliminación" modal className="p-fluid" 
            footer={assesmentDialogFooter}
        >            
            <div className="confirmation-content">
                <div className="p-fluid" >                    
                    <span style={{ fontSize: '1.4rem' }}>⚠️ ¿Esta seguro que desea eliminar la evaluación?</span>
                </div>
                <p>
                    Evaluador <b>{assesment?.user?.name || assesment?.user?.email}</b><br/>
                    Evaluado <b>{assesment?.candidate?.name || assesment?.candidate?.email}</b><br/>
                    Relación <b>{assesment?.relationship?.name}</b>
                </p>
            </div>
        </Dialog>
    )
}

export const AssesmentDialogChangeStatus = ({visible, onHide, assesment}) => {

    const CHANGE_STATUS_ASSESMENT = gql`
    mutation assesment_status($id: Int!, $statusId: Int) {
        assesment(id:$id, statusId:$statusId) {
          code
          success
          message
          assesment {
            id
            status { code:id name color }
            relationship { id code:id name color minimum maximum}
          }
        }
      }
    `

    const [ChangeStatusAssesment] = useMutation(CHANGE_STATUS_ASSESMENT, {
        variables: {},
        onCompleted: ({assesment_mutation}) => {                    
          setVisibleThankyouDialog(true);
          onHide();
        },
      });

    const [visibleThankyouDialog, setVisibleThankyouDialog] = useState<boolean>(false);

    const changeStatusAssesment = () => {
        const variables = { 
          id: assesment?.id || 0
        , statusId:5
        }
        //console.warn(variables)
        ChangeStatusAssesment({
            variables: variables
        });        
    };

    const assesmentDialogFooter = 
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Si" icon="pi pi-check" onClick={changeStatusAssesment} />     
        </React.Fragment>
    ;

    return(
    <>
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }} 
            breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Confirmar la finalización de la evaluación" modal className="p-fluid" 
            footer={assesmentDialogFooter}
        >            
            <div className="confirmation-content">
                <div className="p-fluid" >                    
                    <span style={{ fontSize: '1.4rem' }}>⚠️ ¿Esta seguro que desea finalizar la evaluación?</span>
                </div>
                <p>
                    Evaluador <b>{assesment?.user?.name || assesment?.user?.email}</b><br/>
                    Evaluado <b>{assesment?.candidate?.name || assesment?.candidate?.email}</b><br/>
                    Relación <b>{assesment?.relationship?.name}</b>
                </p>
            </div>
        </Dialog>
        <Dialog 
          visible={visibleThankyouDialog}
          header="Evaluación finalizada"
          footer={<Button label="Ok" icon="pi pi-check" onClick={() => setVisibleThankyouDialog(false)} autoFocus />}
          modal           
          style={{ width: '50rem' }} onHide={() => {if (!visibleThankyouDialog) return; setVisibleThankyouDialog(false); }}>
            Hemos registrado sus respuestas. Gracias por participar en este importante proceso de retroalimentación. Le recordamos que todas las respuestas son confidenciales y serán revisadas de manera resumido por personas autorizadas.
          
        </Dialog>
    </>
    )
}

export const AssesmentDialogSend = ({visible, onHide, assesment, setAssesment}) => {

    const SEND_ASSESMENT = gql`
    mutation assesment_send($id: Int!) {
        assesment_send(id:$id)
    }
    `

    const [SendAssesment/*, SendAssesmentReturn*/] = useMutation(SEND_ASSESMENT, {
        variables: {},
        onCompleted: ({assesment_delete}) => {          
          onHide();          
        },
      });

    const sendAssesment = () => {
        const variables = { 
            id: assesment?.id || 0
        }
        SendAssesment({
            variables: variables
        });        
    };

    const assesmentDialogFooter = 
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={onHide} />
            <Button label="Si" icon="pi pi-check" onClick={sendAssesment} />
        </React.Fragment>
    ;

    return(
        <Dialog visible={visible} onHide={onHide}
            style={{ width: '32rem' }} 
            breakpoints={{ '960px': '75vw', '641px': '90vw' }} 
            header="Confirmar envio de notificación" modal className="p-fluid" 
            footer={assesmentDialogFooter}
        >            
            <div className="confirmation-content">
                <div className="p-fluid" >                    
                    <span style={{ fontSize: '1.4rem' }}>⚠️ ¿Esta seguro que desea notificar al evaluador?</span>
                </div>
                <p>
                    Evaluador <b>{assesment?.user?.name || assesment?.user?.email}</b><br/>
                    Evaluado <b>{assesment?.candidate?.name || assesment?.candidate?.email}</b><br/>
                    Relación <b>{assesment?.relationship?.name}</b>
                </p>
            </div>
        </Dialog>
    )
}

export const AssesmentCard = ({assesment}) => {
    return (
        <Card 
          title={assesment?.name || "No Name"}
          subTitle={assesment?.description || "No Description"}
          className="surface-ground mt-5 mb-5" 
        >
          <p>Evaluación para: {assesment?.candidate?.name} ({assesment?.candidate?.email})</p>
          <p>Evaluado por: {assesment?.user?.name} ({assesment?.user?.email})</p>
          <p>Estatus: <StatusColumn status={assesment?.status}/></p>
          <p>Porcentaje Completado: {assesment?.completed||0}%</p>
        </Card>
    )
} 