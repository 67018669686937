import React from 'react';
import { useQuery } from "@apollo/client"
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';

interface DropdownGQLProps {
    id: string; 
    value:string
    placeholder?: string; 
    label?: string;
    optionLabel?:string
    editable?: boolean
    itemTemplate?:any
    valueTemplate?:any
    onChange?:any
    gqlValues:any
    gqlData:string
  }
 
export const DropdownGQL = ({id, value,placeholder, label,optionLabel,editable,itemTemplate,valueTemplate, onChange, gqlValues, gqlData}:DropdownGQLProps) => {
          
    const getLovReturnValues = useQuery(gqlValues);
    let options = ["..Loading"];
    if(getLovReturnValues && getLovReturnValues.data) {
        if(gqlData in getLovReturnValues.data)
            options=getLovReturnValues?.data[gqlData];
        else
            options = [`${gqlData} no loaded`]
    }

    return(
    <FloatLabel className={label?"mt-6":''}>
        <Dropdown id={id} placeholder={placeholder}
            value={value} 
            onChange={onChange}
            optionLabel={optionLabel||"name"}  
            editable = {editable||false} 
            options={options}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}            
        />
        <label htmlFor={id}>{label}</label>
    </FloatLabel>    
    )
}