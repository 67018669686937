import React from "react";
import {useState} from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner';
import { PanelMenu } from 'primereact/panelmenu';
import { Badge } from 'primereact/badge';

export const GET_ASSESMENTS = gql`
query count {
  license_key_count
  assesments_count
}
`
export const HomePage = () => {

  const {loading, error, data} = useQuery(GET_ASSESMENTS, {
    variables: {}
  });

  const itemRenderer = (item, options) => (
    <a className="flex align-items-center px-3 py-2 cursor-pointer" onClick={options.onClick}>
        <span className={`${item.icon} text-primary`} />
        <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
        {item.badge && <Badge className="ml-auto" value={item.badge} />}
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
);  
  const items = [
    {
        key: '1',
        label: 'Evaluaciones',
        icon: 'pi pi-key',
        badge:0,
        template:itemRenderer,
        items: [
            {
                key: '1_1',
                label: 'Ver Evaluación',
                url: '/license',
            },
            {
                key: '1_2',
                label: 'Adicionar/Comprar Evaluación',
                url: '/license?add=1',
            }
        ]
    }
  ];

  const [expandedKeys, setExpandedKeys] = useState<any>({'1':true});  

  const toggleAll = () => {
    if (Object.keys(expandedKeys).length) {
        collapseAll();
    } else {
        expandAll();
    }
};

  const expandAll = () => {
      items.forEach(expandNode);
      setExpandedKeys({ ...expandedKeys });
  };

  const collapseAll = () => {
      setExpandedKeys({});
  };

  const expandNode = (node) => {
      if (node.items && node.items.length) {
          expandedKeys[node.key] = true;
          node.items.forEach(expandNode);
      }
  };  
  if(data) {
    items[0].badge = data?.license_key_count || 0;    
  }
  
  return (
    <div className="card flex flex-wrap justify-content-center gap-2"  >
      <div style={{width:900}}>

      
      <QueryResult error={error} loading={loading} data={data} >
      <p>
        <PanelMenu model={items} 
          expandedKeys={expandedKeys} 
          onExpandedKeysChange={setExpandedKeys} 
          className="w-full md:w-20rem" 
          multiple           
        />        
      </p>
      </QueryResult>



      </div>
    </div>
  );
};

export default withAuthenticationRequired(HomePage, {
  onRedirecting: () => <SpinnerContainer />,
});