import React from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner';
import { Users } from '../components/Users.tsx';

export const GET_ASSESMENTS = gql`
query CoacheeQ {
  users(isCandidate:true) {
    id
    name
    email
    assesments_c { id,completed }
  }
}
`
export const CoacheesPage = () => {

  const {loading, error, data} = useQuery(GET_ASSESMENTS, {
    variables: {}
  });

  
  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
      <div>
      <p>Listado de los coachees (usuarios con evaluaciones)</p>
      <QueryResult error={error} loading={loading} data={data} >
        <Users users={data?.users}/>        
      </QueryResult>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(CoacheesPage, {
  onRedirecting: () => <SpinnerContainer />,
});