import React from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";


import { InputText } from 'primereact/inputtext';
import { Editor } from 'primereact/editor';

export const GET_PILLARS = gql`
query listPillars {
    pillars {
        id
        name
        description
    }
}
`
export const Pillars = () => {

    const {loading, error, data} = useQuery(GET_PILLARS, {
      variables: {}
    });
  
    return (
    <div><p>In Pillars</p>
      <QueryResult error={error} loading={loading} data={data} >
        {data?.pillars 
         ?
         data.pillars
         .map( pillar =>
          <div key={pillar.id}>
           <p><b>Pillar ID <Link to={`/pillar/${pillar.id}`}>{pillar.id}🔗</Link></b> name {pillar.name} description {pillar.description}</p>
          </div>
         )
         : <p>No Pillars</p>
        }
      </QueryResult>
    </div>
    );
};

export const Pillar = () => {

    const {pillarId}=useParams();

    const {loading, error, data} = useQuery(GET_PILLARS, {
      variables: {}
    });
  
    return (
    <div><p>In Pillar f {pillarId}</p>
      <QueryResult error={error} loading={loading} data={data} >
        {data?.pillars 
         ?
         data.pillars
         .filter(pillar => pillar.id === pillarId)
         .map( pillar =>
          <form className="card" key={pillar.id}>
           <p>ID: {pillar.id} name {pillar.name} description {pillar.description}</p>
           <InputText value={pillar.name} onChange={(e) => console.info(e.target.value)} />
           <Editor value={pillar.description} onTextChange={(e) => console.info(e.htmlValue)} style={{ height: '320px' }} />
          </form>
         )
         : <p>No Pillars</p>
        }
      </QueryResult>
    </div>
    );
};