import React from 'react';

import {
  useEffect
, useState
, useRef
 } from 'react';

import { 
  useQuery
, gql } 
from '@apollo/client';

import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';

import { 
  AssesmentDialog
, AssesmentDialogDelete
, AssesmentDialogSend
 } from './AssesmentDialog.tsx';

import {
 setAllowAssesment
} from "../utils/security.ts"

import { StatusColumn } from "./statusColumn.tsx"
import { LicenseKeyDialogChangeStatus } from "./LicenseKeyDialog.tsx"

 export const AssesmentsByRelationship = ({assesments,licenseKey}) => {
    const GET_RELATIONSHIPS = gql`
    query relationships {
        relationships {
            id
            code:id
            name
            color
            maximum
            minimum
        }
    }
    `;
    const { data} = useQuery(GET_RELATIONSHIPS);
    
    const [assesmentRows, setAssesmentRows] = useState(assesments);
    
    useEffect(() => {
        setAssesmentRows(assesments);    
    },[assesments,assesmentRows,licenseKey]);

    const [dialogVisible, setDialogVisible] = useState<boolean>(false);

    let summary = [];
    if(data) {
        summary = data?.relationships.filter(r=>Number(r.id)!==1);
        summary = summary.map(r =>  ({...r, actual: assesmentRows.filter(a=>Number(a.relationship.id)===Number(r.id)).length}) )
    }

    const header = 
        <div className="flex align-items-center justify-content-between">  
            <span className="text-xl text-900 font-bold">Resumen por relación</span>
        </div>
    ;

    const footer = 
        <ColumnGroup>
            <Row>
                <Column />
                <Column footer="Total"/>
                <Column footerStyle={{ textAlign: 'right' }} footer={summary.reduce((accumulator,b)=>accumulator+b.minimum,0)}/>
                <Column footerStyle={{ textAlign: 'right' }} footer={summary.reduce((accumulator,b)=>accumulator+b.maximum,0)}/>
                <Column footerStyle={{ textAlign: 'right' }} footer={summary.reduce((accumulator,b)=>accumulator+b.actual,0)}/>
            </Row>
        </ColumnGroup>;

    const checkColumn = (relationship) => {
        if( relationship
        && relationship.actual >= relationship.minimum
        && relationship.actual <= relationship.maximum
        )
        return "✔️"
        //else return JSON.stringify(relationship)
    }

    const hideChangeStatusDialog = () => {
        setDialogVisible(false);
      };
  
    const openChangeStatus = () => {
        setDialogVisible(true);
    };

    return(
        <div><p></p>Debe seleccionar al menos cinco evaluadores en cada tipo de relación. Tambien es preferible alcanzar el mínimo de evaluadores por cada tipo de relación.
        <DataTable value={summary}
            header={header} 
            footerColumnGroup={footer}
            size='small'            
        >
            <Column style={{ width: '10px' }} alignHeader="center" bodyStyle={{ textAlign: 'center' }} field="id" body={checkColumn}/>
            <Column style={{ width: '50px' }} alignHeader="left"   bodyStyle={{ textAlign: 'left' }}   field="name" header="Relación"/>
            <Column style={{ width: '10px' }} alignHeader="right"  bodyStyle={{ textAlign: 'right' }}  field="minimum" header="Mín"/>
            <Column style={{ width: '10px' }} alignHeader="right"  bodyStyle={{ textAlign: 'right' }}  field="maximum" header="Max" />
            <Column style={{ width: '10px' }} alignHeader="right"  bodyStyle={{ textAlign: 'right' }}  field="actual" header="Actual" />
        </DataTable><br/>

        {  Number(licenseKey?.status?.id) === 1 
        && <Button label="Completar y Enviar notificaciones" icon="pi pi-send" severity="success"  tooltip='Crear una evaluación nueva' onClick={openChangeStatus}/>
        }

        <LicenseKeyDialogChangeStatus
            visible={dialogVisible}
            onHide={hideChangeStatusDialog}
            licenseKey={licenseKey}
            statusId={3}
        />
        </div>
    )
}

export const Assesments = ({assesments,assesments_count, licenseKey}) => {
    let emptyAssesment = {
        id: null,
        name: null,
        description: null,
        user: null,
        candidate:null,
        relationship:null,
        licenseKey:null,
    };

    const { user } = useAuth0();
    const [assesmentDialog, setAssesmentDialog] = useState(false);
    const [assesmentDeleteDialog, setDeleteAssesmentDialog] = useState(false);
    const [assesmentSendDialog, setSendAssesmentDialog] = useState(false);
    const [assesment, setAssesment] = useState(emptyAssesment);

    const [assesmentRows, setAssesmentRows] = useState(assesments);
    useEffect(() => {
        setAssesmentRows(assesmentRows);    
    },[assesments,assesments_count,licenseKey]);

    const toast = useRef<Toast>(null);

    const setAssesmentChild = (paramAsssesment, dialogUsed) => {
        let { success, message, assesment } = paramAsssesment
        if(!success) {
            if(toast && toast.current)
                toast.current.show({ 
                severity: 'error'
                , summary: 'Error'
                , detail: message
                , life: 3000
                });
        } else {
            let operation = ""
            let _assesments = [...assesmentRows]

            if(dialogUsed==="modify") {
                const index = _assesments.findIndex( e => e.id === assesment.id);
                if(index === -1) {
                    operation = "creada"
                    _assesments.push(assesment);
                } else {
                    operation = "actualizada"
                    _assesments[index] = assesment;
                }
            } else {
                operation = "eliminada";
                _assesments = _assesments.filter((val) => val.id !== assesment.id);
            }
            setAssesmentRows(_assesments)
            if(toast && toast.current)
                toast.current.show({ 
                severity: 'success'
                , summary: 'Exito'
                , detail: `Evaluación ${operation} exitosamente!`
                , life: 1500
                });
        }
    }

    const navigate = useNavigate();

    const onRowSelect = (event) => {
        if(user?.rolesId?.includes("Supervisor"))
            navigate(`/assesment/${event.data.id}`);
    };    

    const CompletedColumn = ({completed}) =>         
        (completed||0).toFixed(0).concat("%")

    const relationshipColumn = ({relationship}) =>        
        <Tag style={{background: relationship?.color}} value={relationship?.name}></Tag> 

    const openNew = () => {
        setAssesment(emptyAssesment);      
        setAssesmentDialog(true);
    };

    const hideDialog = () => 
        setAssesmentDialog(false);

    const hideDeleteDialog = () => 
        setDeleteAssesmentDialog(false);

    const hideSendDialog = () => 
        setSendAssesmentDialog(false);

    const viewAssesment = (newAssesment) => 
        navigate(`/assesment/${newAssesment.id}`)

    const editAssesment = (newAssesment) => {
        setAssesment({ ...newAssesment });
        setAssesmentDialog(true);
    };

    const sendAssesment = (newAssesment) => {
        setAssesment({ ...newAssesment });
        setSendAssesmentDialog(true);
    };

    const confirmDeleteAssesment = (assesment) => {
        setAssesment(assesment);
        setDeleteAssesmentDialog(true);
    };


    const header = () => {
        const allowAssesment = setAllowAssesment(user?.email,licenseKey,null); 

        return(
            <div className="flex align-items-center justify-content-between">                
                <span className="text-xl text-900 font-bold">Evaluaciones</span>                
                {allowAssesment?.add && 
                 <Button icon="pi pi-plus" label="Nueva Evaluación"
                    rounded size='small' severity="success"
                    tooltip='Crear una evaluación nueva'
                    visible={allowAssesment.add}
                    onClick={openNew}
                 />
                }             
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {

        const allowAssesment = setAllowAssesment(user?.email,licenseKey,rowData);

        return (
            <React.Fragment>
                <Button icon="pi pi-eye" outlined className="mr-1"
                   rounded size='small'
                   tooltip='Consultar las preguntas de evaluación'
                   visible={allowAssesment.view} 
                   onClick={() => viewAssesment(rowData)}
                />
                <Button icon="pi pi-pencil" outlined className="mr-1"
                   rounded size='small'
                   tooltip='Modificar la evaluación'
                   visible={allowAssesment.edit}
                   onClick={() => editAssesment(rowData)}
                />
                <Button icon="pi pi-send" outlined className="mr-1"
                   rounded size='small'
                   tooltip='Enviarle un recordatorio al evaluador por correo electrónico'
                   visible={allowAssesment.send}
                   onClick={() => sendAssesment(rowData)}
                />
                <Button icon="pi pi-trash" outlined className="mr-1" 
                   rounded size='small' severity="danger"
                   tooltip='Eliminar la evaluación'
                   visible={allowAssesment.delete}
                   onClick={() => confirmDeleteAssesment(rowData)}
                />
            </React.Fragment>
        );
    };
    
    return(
    <div>
        <Toast ref={toast} />
        
        <DataTable value={assesmentRows} header={header}
            selectionMode="single" 
            onRowSelect={onRowSelect}
            size='small'
            removableSort
            paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}
        >
            <Column sortable field="user.name" header="Evaluador"/>
            <Column sortable field="candidate.name" header="Candidato"/>
            <Column sortable field="relationship.name" header="Relación" body={relationshipColumn}/>
            <Column sortable field="status.name" header="Estado" body={StatusColumn}/>
            <Column sortable field="completed" header="Completado" align={'right'} body={CompletedColumn}/>
            <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }} align={'right'}></Column>
        </DataTable>
        <AssesmentDialog 
            visible={assesmentDialog}
            onHide={hideDialog}
            assesment={assesment}
            setAssesment={setAssesmentChild}
            licenseKey={licenseKey}
        />
        <AssesmentDialogDelete 
            visible={assesmentDeleteDialog}
            onHide={hideDeleteDialog}
            assesment={assesment}
            setAssesment={setAssesmentChild}
        />
        <AssesmentDialogSend
            visible={assesmentSendDialog}
            onHide={hideSendDialog}
            assesment={assesment}
            setAssesment={setAssesmentChild}
        />
    </div>
    )
/*
            <Column sortable field="id" header="Id"/>
            <Column sortable field="name" header="Nombre"/>            
            <Column sortable field="description" header="Descripción"/>
*/
} 