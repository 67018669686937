import React from "react";
import { useParams } from "react-router-dom";
import { 
  withAuthenticationRequired 
} from "@auth0/auth0-react";

import { useQuery, gql } from '@apollo/client';
import QueryResult  from "../components/query-results.tsx";
import SpinnerContainer from '../components/Spinner/index.js';
import { AssesmentsByRelationship, Assesments } from '../components/Assesments.tsx';
import {
 LicenseKeyCard
}  from "../components/LicenseKeyDialog.tsx"

export const GET_LICENSEKEYS = gql`
query licenseKey($id:Int) {
  license_key (id:$id){
    licenseKey
    id
    description
    createdBy { id code:id email name }
    candidate { id code:id email name }    
    status { id code:id name  color }
    assesments {
      id
      name
      description
      completed
      user { id code:id email name }
      candidate { id code:id email name }
      relationship { id code:id name color minimum maximum}
      status { id code:id name  color }
    }
  }
}
`
export const LicensesKeyPage = () => {
  const {licenseKeyId}=useParams();

  const {loading, error, data} = useQuery(GET_LICENSEKEYS, {
    variables: {
      id: Number(licenseKeyId)
    }
  });

  return (
    <div className="card flex flex-wrap justify-content-center gap-2">
    <div>
      <h1>Evaluación</h1>
      <QueryResult error={error} loading={loading} data={data} >
        <LicenseKeyCard licenseKey={data?.license_key[0]}/>

        <div className="flex column-gap-4 align-content-start ">
         <div>
          <div style={{width:900}}>
           <p>Como coachee, debera ingresar el coreo electronicóo y el nombre de las personas que le proveeran retroalimentación (evaluadores). Deberá especificar la relación que el evaluador tiene con usted</p>
           <p>Una ves que complete la lista, podrá enviarles una notificación por correo elecronico el cual les indicará su participación en esta evaluación. La notificación explicará el proceso junto con las instruciones de como utilizar esta herramienta</p>
          </div>          
          <Assesments 
            assesments={data?.license_key[0].assesments} 
            licenseKey={data?.license_key[0]}
          />
         </div>
         <div class="flex w-25rem ">
          <AssesmentsByRelationship
            assesments={data?.license_key[0].assesments}
            licenseKey={data?.license_key[0]}
          />
         </div>
        </div>

      </QueryResult>
    </div>
    </div>
  );
};

export default withAuthenticationRequired(LicensesKeyPage, {
  onRedirecting: () => <SpinnerContainer />,
});