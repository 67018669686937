
export function compareEmail(
    email1 : string | undefined
  , email2 : string | undefined
) : boolean {
    return email1?.toUpperCase() === email2?.toUpperCase()
}

export function setAllowAssesment  (
    userEmail : string | undefined
  , licenseKey
  , assesment
  )  {
      const statusId : number = parseInt(assesment?.status?.id);
      const statusIdLicenseKey : number = parseInt(licenseKey?.status?.code) || -1;
      
      const isSelf    : boolean = compareEmail(userEmail, assesment?.user?.email);
      const isCoach   : boolean = compareEmail(userEmail, licenseKey?.createdBy?.email);
      const isCoachee : boolean = compareEmail(userEmail, licenseKey?.candidate?.email); 
      //const isSupervisor : boolean = user?.rolesId.includes("Supervisor");
      //Nuevo 1, Invitado 2, Iniciado 3, Recazado 4, Finalizado 5
      const allowAssesment = {
          add:    (
                       ![-1,3,5].includes(statusIdLicenseKey)  // License key is not started or ended
                    && (isCoachee || isCoach)               // License Key belongs to user(coachee) or Coach
                  ) || false
        , edit:   (
                      ![-1,3,5].includes(statusIdLicenseKey)  // License key is not started or ended
                   && ![3,5].includes(statusId)          // Assesment is not started or ended
                   && (isCoach)               // License Key belongs Coach
                  )
                  || false                                 // Default to false
        , delete: (
                      ![-1,3,5].includes(statusIdLicenseKey)  // License key is not started or ended
                   && ![3,5].includes(statusId)            // Assesment is not started or ended
                   && (isCoach)                            // License Key belongs to  Coach
                  )
                  || false                                 // Default to false
        , view:   (
                      isSelf || isCoach                    // Assesment belongs to user(coachee) or License Key to Coach 
                  )                    
        , send:   (
                      [3].includes(statusIdLicenseKey)     // License key is started
                  && ![5].includes(statusId)               // Assesment is NOT finished
                  && isCoachee                             // Assesment belongs to user(coachee), Coach cannont send
                  )
                  || false                                 // default to false
        , editAnswer : (
                     [3].includes(statusIdLicenseKey)     // License key is not started or ended
                  && [1,3].includes(statusId)                // Assesment is not started or ended
                  && (isCoachee || isCoach)                // License Key belongs to user(coachee) or Coach
                  )
                  || false                                 // Default to false
      };
/*
      console.info("userEmail",userEmail)
      console.info("licenseKey",licenseKey);
      console.info("licenseKey?.createdBy?.email",licenseKey?.createdBy?.email);
      console.info("licenseKey?.candidate?.email",licenseKey?.candidate?.email); 
      console.info("assesment",assesment,"assesment?.user?.email",assesment?.user?.email)
      console.info("statusId",statusId)
      console.info("statusIdLicenseKey",statusIdLicenseKey)
      console.info("isSelf",isSelf)
      console.info("isCoach",isCoach)
      console.info("isCoachee",isCoachee)
      console.info("allowAssesment",JSON.stringify(allowAssesment,null,2))
// */ 
      return allowAssesment;
}

const security = {
  compareEmail
, setAllowAssesment
};

export default security;