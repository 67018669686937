import React from 'react';
import Spinner from './Spinner';


/**
 * Query Results conditionally renders Apollo useQuery hooks states:
 * loading, error or its children when data is ready
 */
const QueryResult = ({ loading, error, data, children }) => {

  if (error) {
    if(!Array.isArray(error)) 
      error = [error];    
    
    return (
    error.map( (iErr, iKey) => 
    <div key={iKey}>
     <div><b>ERROR:</b> {iErr.message}</div>
     {iErr?.graphQLErrors && (
      <div>
       <b>graphQL Errors ({iErr.graphQLErrors.length || 0}):</b>
       <ul>{iErr.graphQLErrors.map((error,iK)=> <li key={iK}>{error.message} / {JSON.stringify(error.path)}</li>)}</ul>
      </div>
     )}
     {(iErr?.clientErrors && iErr.clientErrors.length ) ? (
      <div>
       <b>client Errors ({iErr.clientErrors.length || 0}):</b><br/>
       <br/><ul>{iErr.clientErrors.map((error,iK)=> <li key={iK}>{error.message} / {JSON.stringify(error.path)}</li>)}</ul>
      </div>
     ) : <></>}
     {iErr?.networkError && (
      <div>
       <b>network Errors <i>{iErr.networkError?.name}</i> :</b><br/>
       <br/><ul><li>{iErr.networkError?.message}</li></ul>
      </div>
     )}
    </div>
    ));
  }
//<b>network Errors <i>{iErr.networkError.name}</i> ({iErr.networkError?.length || 0}):</b><br/>
//<b>result errors ( iErr.networkError.result.errors.length ):</b>
//<br/><ul>{iErr.networkError.result.errors.map((error,iK)=> <li key={iK}>{error.message}</li>)}</ul>
  if (loading) {
    return (
      <SpinnerContainer>

      </SpinnerContainer>
    );
  }
  if (!data) {
    return <div>Nothing to show...</div>;
  }
  if (data) {
    return children;
  }
};


export default QueryResult;

/** Query Result styled components */

const SpinnerContainer = (props) => (
<Spinner/>
);